import { h, reactive, readonly, ref } from 'vue'

import { I18N_USAGE_KEY, I18N_USAGE_UNDEFINED_KEY, SUPPORTED_LANGUAGES } from '@/config/constants'

// DATA
const __browserLanguage = document.documentElement.getAttribute('lang')?.substr(0, 2)?.toUpperCase()
const FALLBACK_LANGUAGE = 'DE'
const __selectedLanguage = ref(
  SUPPORTED_LANGUAGES.map(s => s.value).includes(__browserLanguage) ? __browserLanguage : FALLBACK_LANGUAGE
)
const placeholderRegexp = new RegExp('{([^{}]+?)}', 'g')
const translations = reactive({})
const usagesKey = new Set()
const usagesNotAvailable = new Set()

// METHODS
function getTranslation(key) {
  const translation = key.split('.').reduce((o, i) => {
    if (o) return o[i]
  }, translations[__selectedLanguage.value])
  usagesKey.add(key)
  if (translation === undefined) usagesNotAvailable.add(key)
  return translation
}

export function handlePlaceholder(message, placeholders) {
  let matches = [...message.matchAll(placeholderRegexp)]

  if (!matches.length) return message

  return matches.reduce((currentMessage, match) => {
    const [placeholder, placeholderKey] = match
    const replacement = placeholders[placeholderKey.trim()]

    if (replacement) {
      return currentMessage.replace(placeholder, replacement.toString())
    } else if (import.meta.env.PROD) {
      // Prevent showing unresolved placeholders on PRD
      return currentMessage.replace(placeholder, '')
    }

    return currentMessage
  }, message)
}

// @TODO: Only for statistics about used keys. Can be removed after cleanup
function updateUsageStorage(key, values) {
  const storageUsage = JSON.parse(localStorage.getItem(key)) || []
  const mergedUsage = new Set([...storageUsage, ...values])
  localStorage.setItem(key, JSON.stringify([...mergedUsage]))
}

function validateLanguage(language) {
  if (!SUPPORTED_LANGUAGES.map(s => s.value).includes(language)) {
    if (window.ineum) {
      window.ineum('translation language does not exist', language)
    }
    return undefined
  }
  return language
}

// EXPORTS
export default {
  install: app => {
    ;(app.config.globalProperties.$t = t), (app.config.globalProperties.$te = te)
    // @TODO can be removed again after cleanup of translations
    if (!import.meta.env.PROD) {
      setInterval(() => {
        storeUsage()
      }, 5000)
    }
  },
}

export const browserLanguage = __browserLanguage

export const getLangTranslations = language => {
  const lang = validateLanguage(language)
  if (lang) {
    return translations[lang]
  }
}

/*
 * `<i18n-t keypath="" tag="">` component to render a translation and replace placeholders with slot templates from the component children.
 *
 * keypath: string  The translation key
 * tag: string  The component to use for the rendered wrapper
 *
 * Example:
 * Translation content 'example.text': `Please correct your {name}`
 * Template content:
 * <i18n-t keypath="example.text" tag="p">
 *  <template #name><a href='/name'>name: {{ $firstName $lastName}}</a></template>
 * </i18n-t>
 */
export const i18nT = (props, { slots }) => {
  const translatedString = getTranslation(props.keypath)
  if (translatedString) {
    const parts = translatedString.split(placeholderRegexp)
    const replacedParts = parts.map((item, i) => {
      if (i % 2 === 1) {
        // Each second is the key of a placeholder/slot
        return slots[item] ? slots[item]() : ''
      }
      return item
    })
    return h(props.tag ?? 'div', [...replacedParts])
  }
  return translatedString
}

export const setLang = language => {
  const lang = validateLanguage(language)
  if (lang) {
    __selectedLanguage.value = lang
  }
}

export const selectedLanguage = readonly(__selectedLanguage)

// Sets the vocabular for a language AND the selectedLanguage
export const setLangTranslations = (language, vocabular) => {
  const lang = validateLanguage(language)
  if (lang) {
    __selectedLanguage.value = lang
    translations[lang] = vocabular
  }
}

// @TODO: Only for statistics about used keys. Can be removed after cleanup
export const storeUsage = () => {
  updateUsageStorage(I18N_USAGE_KEY, usagesKey)
  updateUsageStorage(I18N_USAGE_UNDEFINED_KEY, usagesNotAvailable)
}

export const t = (key, placeholders = {}) => {
  const translatedString = getTranslation(key)
  if (typeof translatedString != 'string') {
    return translatedString
  }
  return handlePlaceholder(translatedString, placeholders)
}

export const te = key => {
  return getTranslation(key) !== undefined
}
