import { isRequired, MissingParameterError } from '@/utils/CustomErrors'

import config from '@/config'

import useBackend from '@/hooks/useBackend'
import useBrowser from '@/hooks/useBrowser'
import useI18n from '@/hooks/useI18n'

function basket() {
  // HOOKS
  const { buildURL, setup, doGet, doPost, doPut } = useBackend()
  const { isSafari } = useBrowser()
  const { selectedLanguage } = useI18n()

  // INIT
  setup('/ovpv3/public', config.microserviceBaseUrl)

  // METHODS

  /**
   * createBasket sends a POST-request to /baskets to create a new basket
   * @param {Basket} payload
   * @returns {Promise<Basket>} created basket
   */
  async function createBasket(payload = isRequired('payload')) {
    if (!payload.language) {
      payload.language = selectedLanguage.value
    }
    const response = await doPost('/baskets', payload)
    return response.data
  }

  /**
   * createBasketForPartnerNumber sends a POST-request to /userregistry/baskets to create a basket for a partnerNumber
   * @param {object} params
   * @param {string} params.partnerNumber
   * @param {string} params.uid
   * @param {Basket} payload
   * @returns {Promise<Basket>} created basket
   */
  async function createBasketForPartnerNumber(params, payload) {
    const { partnerNumber, uid } = params
    if (!partnerNumber) throw new MissingParameterError('partnerNumber')
    if (!uid) throw new MissingParameterError('uid')

    const url = buildURL('/userregistry/baskets', { partnerNumber, uid })
    const response = await doPost(url, payload)
    return response.data
  }

  /**
   * getBasketyById sends a GET-request to /baskets/{basketId} to get an existing basket via basketId
   * As the jwToken might not yet be stored in the session for certain calls, we have to provide it directly via parameter
   * @param {string} basketId
   * @param {string} jwToken
   * @returns {Promise<Basket>} basket
   */
  async function getBasketById(basketId = isRequired('basketId'), jwToken) {
    const response = await doGet(`/baskets/${basketId}`, { timestamp: Date.now() }, false, {
      headers: { 'X-Softlogin-Token': jwToken },
    })
    return response.data
  }

  /**
   * getBasketByPartnerNumber sends a GET-request to /userregistry/get-family to get an existing basket depending on the partnerNumber
   * This basket isn't complete and only contains the information for the persons
   * @param {Object} params
   * @param {string} params.partnerNumber
   * @param {string} params.uid
   * @returns {Promise<Basket>} basket
   */
  async function getBasketByPartnerNumber(params) {
    const { partnerNumber, uid } = params
    if (!partnerNumber) throw new MissingParameterError('partnerNumber')
    if (!uid) throw new MissingParameterError('uid')

    const url = buildURL(`/userregistry/get-family`, { partnerNumber, uid })
    const response = await doGet(url, {})
    return response.data
  }
  /**
   * getBasketByToken sends a GET-request to /baskets to get an existing basket via the reentry token or a tan
   * @param {string} token - reentry token
   * @param {string} tan
   * @returns {Promise<Basket>} basket
   */
  async function getBasketByToken(token = isRequired('token'), tan) {
    const response = await doGet('/baskets', { token, tan })
    return response.data
  }

  /**
   *  getCancellationQuestions sends a GET-request to /baskets/{basketId}/cancellationQuestions
   *  to get the structure for the cancellationQuestions
   *  @param {string} basketId
   *  @returns {Promise<object[]>} cancellationQuestions
   */
  async function getCancellationQuestions(basketId = isRequired('basketId')) {
    const response = await doGet(`/baskets/${basketId}/cancellationQuestions`)
    return response.data.cancellationQuestions
  }

  /**
   *  getCustomerNumber sends a GET-request to /baskets/{basketId}/customerNumber
   *  to validate a customerNumber
   *  @param {string} basketId
   *  @param {object} params
   *  @param {string} params.customerNumber
   *  @returns {Promise<object>}
   */
  async function getCustomerNumber(basketId = isRequired('basketId'), params) {
    if (!params.customerNumber) throw new MissingParameterError('customerNumber')

    const path = buildURL(`/baskets/${basketId}/customerNumber`, { ...params })
    const response = await doGet(path)
    return response.data
  }

  async function getDocument({ basketId = isRequired('basketId'), fileName = isRequired('fileName'), type, jwToken }) {
    const options = {
      responseType: 'blob',
      ...(jwToken && { headers: { 'X-Softlogin-Token': jwToken } }),
    }
    const response = await doGet(`/baskets/${basketId}/${fileName}.pdf`, { type }, false, options)
    window.open(URL.createObjectURL(response), isSafari() ? '_top' : '_blank')
    return Promise.resolve()
  }

  /**
   *  simulateUpdateBasket sends a PUT-request to /basketSimulate/updateProducts to get a simulated (meaning no db change) updated basket
   *  @param {object} params
   *  @param {string} params.personId - for this specific person, check product riskAssessmentRequired from Risk-assessment ms
   *  @param {Basket} payload
   *  @returns {Promise<Basket>} updated basket
   */
  async function simulateUpdateBasket(params = {}, payload) {
    const path = buildURL(`/basketSimulate/updateProducts`, { ...params })
    const response = await doPut(path, payload)
    return response.data
  }

  function signOvpDocument(basketId = isRequired('basketId')) {
    return doGet(`/baskets/${basketId}/signDocument`)
  }

  /**
   * sendSMSTan sends a GET-request to /baskets/smstan to send a reentry token
   * @param {string} token
   * @returns {Promise<Basket>} basket containing mobile and distributionpartners
   */
  async function sendSMSTan(token = isRequired('token')) {
    const response = await doGet('/baskets/smstan', { token })
    return response.data
  }

  async function showVag45CustomerInformation(basketId = isRequired('basketId')) {
    const response = await doGet(`/baskets/${basketId}/requiredCustomerInfo`)
    return response.data
  }

  async function signupOvpContest(__basket = isRequired('basket')) {
    const { basketId, jwToken } = __basket

    const options = {
      responseType: 'blob',
      ...(jwToken && { headers: { 'X-Softlogin-Token': jwToken } }),
    }

    const path = buildURL(`/baskets/${basketId}/contest`)
    return await doPost(path, {}, options).then(response => response.data)
  }

  /**
   * updateBasket sends a PUT-request to /baskets/{basketId} to update an existing basket
   * @param {string} basketId
   * @param {object} params
   * @param {string} params.emailType
   * @param {boolean} params.submit
   * @param {boolean} params.submitScreen
   * @param {boolean} params.signingProcessStarted
   * @param {string} params.signingSmsTan
   * @param {string} params.mobileNumberVerificationSmsTan
   * @param {Basket} payload
   * @returns {Promise<Basket>} updated basket
   */
  async function updateBasket(basketId = isRequired('basketId'), params = {}, payload) {
    const path = buildURL(`/baskets/${basketId}`, { ...params })
    const response = await doPut(path, payload)
    return response.data
  }

  return {
    createBasket,
    createBasketForPartnerNumber,
    getBasketById,
    getBasketByPartnerNumber,
    getBasketByToken,
    getCancellationQuestions,
    getCustomerNumber,
    getDocument,
    sendSMSTan,
    showVag45CustomerInformation,
    signOvpDocument,
    signupOvpContest,
    simulateUpdateBasket,
    updateBasket,
  }
}

export default basket()
