export const STORAGE_KEY = 'sanitas-ovp'
export const FORCED_LOGOUT_KEY = 'sanitas-forced-logout'
export const I18N_USAGE_KEY = 'sanitas-ovp-i18n-usage'
export const I18N_USAGE_UNDEFINED_KEY = 'sanitas-ovp-i18n-usage-undefined'

export const CATEGORY_TYPE = {
  KVG: 'KVG',
  HOSP: 'HOSP',
  ADDITIONAL: 'ADDITIONAL',
  FURTHER_ADDITIONAL: 'FURTHER_ADDITIONAL',
  UNKONWN: 'OTHER',
}

export const PRE_INSURER_TYPE = {
  KVG: 'KVG',
  VVG: 'VVG',
}

export const COLLECTIVE_CHANNEL = {
  SCPC: 'SCPC',
}

export const CHANNEL = {
  ALVA: 'ALVA',
  OVP: 'OVP',
  ENTRYWIDGET: 'ENTRYWIDGET',
  PARTNERDVP: 'PARTNERDVP',
  PARTNERWIDGET: 'PARTNERWIDGET',
  PORTAL: 'PORTAL',
  PRICEWIDGET: 'PRICEWIDGET',
  PRODUCTWIDGET: 'PRODUCTWIDGET',
  SERVICEPORTAL: 'SERVICEPORTAL',
}

export const SUPPORTED_LANGUAGES = [
  { text: 'DE', value: 'DE' },
  { text: 'FR', value: 'FR' },
  { text: 'IT', value: 'IT' },
  { text: 'EN', value: 'EN' },
]

export const COLLECTIVE_REQUIRED_FLAGS = ['MANDATORY', 'Zwingend']

export const DATE_FORMAT_DIN = 'dd.MM.yyyy'
export const DATE_FORMAT_ISO = 'yyyy-MM-dd'

export const WORK_SITUATION = {
  MIN_AGE: 15,
  MAX_AGE: 65,
}

export const ADULT = 'adult'
export const MINOR = 'minor'
export const SENIOR = 'senior'

export const GENDER = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  UNBORN: 'UNBORN',
}

export const MAX_PERSONS = 7

export const CONTRACT_START_DATE = {
  MAX_MONTHS_PAST: 3,
  MAX_MONTHS_FUTURE: 18,
}

export const FREE_MOVEMENT_OF_PERSONS_COUNTRIES = ['CH', 'EU_EFTA_WITHOUT_PERMIT']

export const PAYMENT_FREQUENCIES = ['MONTHLY', 'BIMONTHLY', 'QUARTERLY', 'HALFYEARLY', 'YEARLY']

export const PAYMENT_METHOD = {
  ESR: 'ESR',
  LSV_PLUS: 'LSV_PLUS',
  EBILLING: 'EBILLING',
  DIRECT_DEBIT: 'DIRECT_DEBIT',
}

export const PAYMENT_METHODS = [
  PAYMENT_METHOD.ESR,
  PAYMENT_METHOD.LSV_PLUS,
  PAYMENT_METHOD.EBILLING,
  PAYMENT_METHOD.DIRECT_DEBIT,
]

export const HQ_TYPES = {
  ALCOHOL: 'alcoholConsumptions',
  DRUG: 'drugConsumptions',
  SMOKING: 'smokingConsumptions',
  TREATMENTS: 'treatmentCases',
}

export const SMOKING_TYPES = ['CIGARETTE', 'CIGAR', 'PIPE', 'E_CIGARETTE', 'CIGARILLOS', 'THS', 'OTHER']
export const ALCOHOL_TYPES = ['BEER', 'WINE', 'LIQUOR', 'OTHER']
export const DRUG_TYPES = ['CANNABIS', 'ANABOLICA', 'HEROIN', 'COCAINE', 'LSD', 'ECSTASY', 'OTHER']

// order is important!
export const TREATMENTS_TYPES = { ACTUAL_OR_PAST: 'ACTUAL_OR_PAST', PLANNED: 'PLANNED' }

export const FRANCHISE_ADULT = [300, 500, 1000, 1500, 2000, 2500]
export const FRANCHISE_MINOR = [0, 100, 200, 300, 400, 500, 600]
export const MOVED_FROM_ABROAD = [
  'CH',
  'PERMIT_C_B_L_LONGER_3_MONTHS',
  'PERMIT_L_UNTIL_3_MONTHS',
  'PERMIT_G',
  'EU_EFTA_WITHOUT_PERMIT',
  'OTHER',
]
export const RESIDENCE_PERMITS = [
  'NOT_KNOWN',
  'B_RESIDENCE_PERMIT',
  'C_SETTLEMENT_PERMIT',
  'CI_RESIDENCE_PERMIT_WITH_WORK',
  'F_PROVISIONALLY_ADMITTED_FOREIGNER',
  'L_SHORT_TERM_RESIDENCE_PERMIT_UPTO_3_MONTH',
  'L_SHORT_TERM_RESIDENCE_PERMIT_FROM_3_MONTH',
  'N_PERMIT_FOR_ASYLUM_SEEKERS',
  'G_CROSS_BORDER_COMMUTER_PERMIT',
  'S_PERSON_IN_NEED_OF_PROTECTION',
  'SANS_PAPIER',
  'SWISS_CITIZEN',
]
export const NO_KVG_REASON_MOVED_TO_CH = 'MOVED_TO_CH'
export const NO_KVG_REASONS = ['CODE_100', 'CODE_148', 'CODE_153', NO_KVG_REASON_MOVED_TO_CH]
export const ENABLE_FILE_UPLOAD = [
  'CH',
  'PERMIT_C_B_L_LONGER_3_MONTHS',
  'PERMIT_L_UNTIL_3_MONTHS',
  'PERMIT_G',
  'CODE_100',
  'OTHER',
]

export const DOCUMENT_TYPE = {
  DOCUMENT_TYPE_CROSS_BORDER_PERMIT: 'crossborderpermit',
  DOCUMENT_TYPE_MILITARY_SERVICE: 'militaryservice',
  DOCUMENT_TYPE_REGISTRATION: 'registration',
  DOCUMENT_TYPE_RESIDENCE_PERMIT: 'residencepermit',
}

export const AIDE_INACTIVITY_MODAL_SEEN = 'aide-inactivity-modal-seen'
export const DOCUMENT_CANCELATION_KVG = 'CANCELATION_KVG'
export const DOCUMENT_CANCELATION_VVG = 'CANCELATION_VVG'
export const DOCUMENTS_TODO = [DOCUMENT_CANCELATION_KVG, DOCUMENT_CANCELATION_VVG]

export const EGATE_ACCEPTED = 'READY_FOR_TRANSMISSION'
export const EGATE_REVIEW = 'REVIEW_PENDING'

export const DOCUMENTS_ALL = 'ALL'
export const DOCUMENTS_OA_HEALTH_QUEST = 'OA_HEALTH_QUEST'
export const DOCUMENTS_INSURANCE = [DOCUMENTS_OA_HEALTH_QUEST, DOCUMENTS_ALL]

export const DOCUMENTS_APPLICATION_DENTAL = 'APPLICATION_DENTAL'

export const DEFAULT_TIME = 1000 // 1 sec in milliseconds
export const DEFAULT_DELAY_TIME = DEFAULT_TIME / 2
export const DEFAULT_FORM_DEBOUNCE_TIME = 200

export const DEFAULT_LANGUAGE = 'de'
export const DEFAULT_MASTER_TREATY = 'SA-EV'
export const DEFAULT_DISTRIBUTION_PARTNER_NUMBER = '219369'

// refer to DistributionPartnerInfoMapper.java in ovpv3 ms for futher mapping information
export const SALES_PARTNER_TYPE = {
  BROKER: 'BROKER', // based on definitionBoid, has advisory protocol
  DEFAULT: 'DEFAULT', // collective and others(like comparis, axa) distributionPartnerNumber
  DEFAULT_SANITAS: 'DEFAULT_SANITAS', // default Sanitas distributionPartnerNumber
  MOBILE: 'MOBILE', // mobile distribution, based on distributionPartnerNumber (FLEX-493)
  PARTNER: 'PARTNER', // partner DVP like smile
  PRIVATE_INSURER: 'PRIVATE_INSURER', // Privatassekuranz, based on masterTreaty
}

export const DISTRIBUTION_PARTNERS = ['BROKER', 'MOBILE', 'PARTNER', 'PRIVATE_INSURER']

// distributionPartnerTypes
export const DISTRIBUTION_PARTNER_TYPE = {
  OFFERTE: 'OFFERTE',
  ABSCHLUSS: 'ABSCHLUSS',
  BETREUER: 'BETREUER',
}

export const DISTRIBUTION_PARTNER_TYPES = [
  DISTRIBUTION_PARTNER_TYPE.OFFERTE,
  DISTRIBUTION_PARTNER_TYPE.ABSCHLUSS,
  DISTRIBUTION_PARTNER_TYPE.BETREUER,
]

export const NOTIFICATION = {
  LIFETIME: DEFAULT_TIME * 10,
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success',
}

export const REENTRY_PREFIX_SP = 'OA'
export const REENTRY_PREFIX_DVP = 'RE'

export const SESSION_EOL = { days: 2 }

export const SESSION_UNINITIALISED = 'session-empty'
export const SESSION_ACTIVE = 'session-active'
export const SESSION_UPDATED_KEY = 'session-updated'

export const STATE = {
  READY: 'READY',
  LOADING: 'LOADING',
}

export const SAVE_NUDGE_DELAY = DEFAULT_TIME

export const COUNTRY_CODES_SWISS_AND_NEIGHBOURS = ['ch', 'de', 'at', 'it', 'fr', 'li']

export const SURVEY_TOUCHPOINT_WEBSITE = 'TP14'
export const SURVEY_TOUCHPOINT_SUBMIT = 'TP15'

export const BASKET_TYPE = {
  BASKET_NEW: 'BASKET_NEW',
  BASKET_CHANGED: 'BASKET_CHANGED',
  DIFF_CHANGED: 'DIFF_CHANGED',
  DIFF_EXISTING: 'DIFF_EXISTING',
  SUBMIT_NEW: 'SUBMIT_NEW',
  ALVA_NEW: 'ALVA_NEW',
}

export const PHONE_NUMBER_TYPE = {
  MOBILE: 'MOBILE',
}

export const PRODUCT_STATE = {
  EDITABLE: 'EDITABLE',
  READONLY: 'READONLY',
  NOT_DELETABLE: 'NOT_DELETABLE',
}

export const PRODUCT_MODIFICATION_TYPE = {
  ORIGINAL: 'ORIGINAL',
  CHANGED: 'CHANGED',
  NEW: 'NEW',
}

// --------- advisory protocol
export const ADVISORY_PROTOCOL = {
  DOCUMENT_TYPE_INSURANCE_POLICY: 'INSURANCE_POLICY',
  DOCUMENT_TYPE_IDENTIFICATION: 'IDENTIFICATION',
}

// --------- Incentive Types ------------
export const INCENTIVE_TYPE = {
  PRODUCT: 'PRODUCT',
  QUIZ: 'QUIZ',
}

// --------- section routing ---------

export const SIGNATURE_MAX_EMPTY_PERCENTAGE = 99.75

export const ROUTE_INDEX = {
  // --------- route indices Aide
  AIDE: {
    ENTRY: 100,
    PERSONS: 110,
    KVG_PRODUCT: 120,
    KVG_PRODUCT_FRANCHISE: 121,
    KVG_PRODUCT_ACCIDENT: 122,
    HOSP_PRODUCT: 130,
    HOSP_PRODUCT_UPGRADE: 131,
    VVG_GROUPS: 140,
    VVG_GROUP: 141,
    BASKET: 15,
  },
  // --------- route indices
  PERSONS: 0,

  OFFERS: 10,
  OFFERS_INCENTIVE: 11,

  BASKET: 20,

  ONBOARDING: 30,

  APPLICATION: 40,
  APPLICATION_PERSONAL_DATA: 41,
  APPLICATION_ADMIN_DATA: 42,
  APPLICATION_EXISTING_CUSTOMER_DATA: 43,
  APPLICATION_HEALTHQUESTIONS: 44,

  SUBMIT: 50,

  ADVISORY_PROTOCOL: 60,

  SIGNATURE_ENTRY: 70,

  SIGNATURE_VERIFICATION: 80,

  NEXT_STEPS: 90,
}

// --------- route names
export const ROUTE_NAME = {
  // --------- route names Aide
  AIDE: {
    ACCIDENT: 'aide-offers-kvg-accident',
    ADDITIONAL: 'aide-offers-additional-group',
    BASKET: 'aide-basket',
    ENTRY: 'aide-entry',
    FRANCHISE: 'aide-offers-kvg-franchise',
    HOSP: 'aide-offers-hosp',
    HOSP_UPGRADE: 'aide-offers-hosp-upgrade',
    KVG: 'aide-offers-kvg',
    OFFERS: 'aide-offers',
    PERSONS: 'aide-persons',
    VVG: 'aide-offers-additional',
  },
  ADVISORY_PROTOCOL: 'advisory-protocol',
  APPLICATION: 'application',
  APPLICATION_PERSONAL_DATA: 'application-personal-data',
  APPLICATION_ADMIN_DATA: 'application-administrative-data',
  APPLICATION_EXISTING_CUSTOMER_DATA: 'application-existing-customer-data',
  APPLICATION_HEALTHQUESTIONS: 'application-healthquestions',
  BASKET: 'basket',
  COLLECTIVE: 'collective',
  NEXT_STEPS: 'next-steps',
  NOT_FOUND: 'notFound',
  OFFERS: 'offers',
  OFFERS_ALT: 'offers-alt',
  OFFERS_INCENTIVE: 'offers-incentive',
  ONBOARDING: 'onboarding',
  PARTNER: 'partner',
  PERSONS: 'persons',
  REENTRY: 'reentry',
  REENTRY_OA: 'reentry.offer-application',
  SIGNATURE: 'digital-signature',
  SIGNATURE_ENTRY: 'digital-signature-entry',
  SIGNATURE_VERIFICATION: 'digital-signature-verification',
  SUBMIT: 'submit',
}

export const ROUTE_URL_PARAMS = {
  CONTRACT_START: 'contractStartDate',
  DATE_OF_BIRTH: 'dateOfBirth',
  LOCATION: 'location',
  IMMIGRANT: 'immigrant',
}
// --------- section routing END ---------

export const PARTNER_WIDGET_RESTART_TIMEOUT = 15 * DEFAULT_TIME

// --------- Existing Customer Login ----------
export const LOGIN_ERROR_STATE = {
  LOCKED: 'LOCKED',
  LOGGED_OUT: 'LOGGED_OUT',
  GENERAL: 'GENERAL',
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  DVP_UNSUPPORTED: 'DVP_CAN_NOT_SUPPORT_EXISTING_CUSTOMER',
}
export const HN_VERIFICATION_STATE = {
  VALID: 'VALID',
  VERIFY_CARD: 'VERIFY_CARD',
  VERIFY_QUESTION: 'VERIFY_QUESTION',
  LOCKED_TEMPORARILY: 'LOCKED_TEMPORARILY',
  VERIFIED: 'VERIFIED',
}

export const LOGIN_STEP = {
  AUTH_HN_FORM: 'auth-hn-form',
  AUTH_HN_CUSTOMER_NUMBER: 'auth-hn-customer-number',
  AUTH_HN_CARD_NUMBER: 'auth-hn-card-number',
  AUTH_HN_QUESTIONS: 'auth-hn-questions',
  AUTH_KUPO_FORM: 'auth-kupo-form',
  AUTH_PERSONS: 'auth-persons-form',
  ERROR_GENERAL: 'error-general',
  ERROR_LOCKED: 'error-locked',
  ERROR_LOGGED_OUT: 'error-logged-out',
  ERROR_DVP_UNSUPPORTED: 'error-dvp-unsupported',
  PERSONS_SELECT: 'persons-select',
  PERSONS_NEW: 'persons-new',
  SUCCESS: 'success',
  EMAIL: 'email',
  MOBILE: 'mobile',
  TAN: 'tan',
  TYPE_SELECTION: 'type-selection',

  LOADING: 'loading',
  WELCOME: 'welcome',
}

export const LOGIN_STEP_CS_ID = {
  [LOGIN_STEP.AUTH_HN_CUSTOMER_NUMBER]: 'BPR_customer-number',
  [LOGIN_STEP.AUTH_HN_CARD_NUMBER]: 'BPR_card-number',
  [LOGIN_STEP.AUTH_HN_QUESTIONS]: 'BPR_security-question',
  [LOGIN_STEP.ERROR_GENERAL]: 'BPR_ERROR_GENERAL',
  [LOGIN_STEP.ERROR_LOCKED]: 'BPR_ERROR_LOCKED',
  [LOGIN_STEP.ERROR_LOGGED_OUT]: 'BPR_ERROR_LOGGED_OUT',
  [LOGIN_STEP.ERROR_DVP_UNSUPPORTED]: 'BPR_ERROR_DVP_UNSUPPORTED',
  [LOGIN_STEP.PERSONS_SELECT]: 'BPR_person-chooser',
  [LOGIN_STEP.PERSONS_NEW]: 'BPR_new-person',
  [LOGIN_STEP.SUCCESS]: 'BPR_between-info',
  [LOGIN_STEP.EMAIL]: 'BPR_email',
  [LOGIN_STEP.MOBILE]: 'BPR_mobile',
  [LOGIN_STEP.TAN]: 'BPR_mobile-tan',
  [LOGIN_STEP.TYPE_SELECTION]: 'BPR_type-selection',
  [LOGIN_STEP.LOADING]: 'BPR_loading',
  [LOGIN_STEP.WELCOME]: 'BPR_welcome-message',
}

export const LOGIN_TYPE = {
  KUPO: 'kupo',
  HN: 'hn',
}

export const LOGIN_OPEN_MODAL = 'open-login-modal'

// --------- Price Widget ----------
export const PRICE_WIDGET_MAX_TABS_SIZE = 3

// ---------- section accounts START ---------

export const ACCOUNT_TYPE = {
  CHILD: 'CHILD',
  HEAD: 'HEAD',
  PARTNER: 'PARTNER',
}

export const PORTAL_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  INVITATION_SENT: 'INVITATION_SENT',
  NOT_OF_LEGAL_AGE: 'NOT_OF_LEGAL_AGE',
}

export const SEGMENT = {
  COMPACT: 'COMPACT',
  PREFERENCE: 'PREFERENCE',
}

export const TYPOGRAPHY = {
  TYPES: {
    heading01: 'heading01',
    heading02: 'heading02',
    heading03: 'heading03',
    heading04: 'heading04',
    heading05: 'heading05',
    heading06: 'heading06',
    bodyMediumLong: 'body-medium-long',
    bodyLargeShort: 'body-large-short',
    bodyLargeLong: 'body-large-long',
    displaySmall: 'display-small',
    displayMedium: 'display-medium',
    displayLarge: 'display-large',
    caption: 'caption',
  },
  TYPE_DEFAULT: 'bodyLargeShort',
}

export const LANGUAGE = {
  EN: 'en',
  DE: 'de',
  FR: 'fr',
  IT: 'it',
}

export const LANGUAGES_CONFIG = [
  {
    id: LANGUAGE.DE,
    href: '/kundenportal',
    title: 'DE',
  },
  {
    id: LANGUAGE.FR,
    href: '/portail-client',
    title: 'FR',
  },
  {
    id: LANGUAGE.IT,
    href: '/portale-clienti',
    title: 'IT',
  },
  {
    id: LANGUAGE.EN,
    href: '/customer-portal',
    title: 'EN',
  },
]

// ---------- section accounts END ---------

export const AGE_RANGES = ['-0', '0-18', '19-26', '27-44', '45-63', '64+']

// ---------- FORMS start ---------
export const FOCUSABLE_INPUT_QUERY = 'input:not([readonly]), textarea:not([readonly]), .select__trigger'
// ---------- FORMS end ---------

// ---------- focus start ---------
export const DEFAULT_FOCUS_DELAY = 50
export const ON_SCREEN_FOCUS_DELAY = 75
// ---------- focus end ---------

// --------- tooltip start ---------
export const TIPPY_THEME = {
  DVP: 'dvp',
  CARD: 'card',
}
// --------- tooltip end -----------
