<template>
  <!-- prettier-ignore -->
  <Teleport
    v-if="isDropdownPresent"
    :to="parentModal ? parentModal : 'body'"
  >
    <div
      ref="dropdownRef"
      v-bind="$attrs"
      class="dropdown"
      :class="classes"
      role="listbox"
      tabindex="-1"
      @keydown.esc="close"
    >
      <template
        v-for="item in $props.options"
        :key="`dropdown-${item.label}`"
      >
        <div
          :id="`${$props.name}-${item.value}`"
          :aria-selected="item.value === $props.selected"
          class="dropdown__item"
          :class="{'disabled': item.disabled}"
          role="option"
          @keydown.tab="selectItem(item)"
          @keydown.enter.prevent="selectItem(item)"
        >
          <input
            tabindex="-1"
            type="radio"
            :name="$props.name"
            :value="item.value"
            :checked="item.value === $props.selected"
            :disabled="item.disabled"
          />
          <label
            class="item__label"
            @click="selectItem(item)"
          >
            <span v-text="item.label" />
            <the-icon
              v-if="item.value === $props.selected"
              art="solid"
              name="check"
            />
          </label>
        </div>
      </template>
    </div>
  </Teleport>
</template>

<script setup>
/**
 * FIGMA: https://www.figma.com/file/3r1w8rChrH97KwxTcUblDL/EP-Basic-Library?node-id=244%3A6421&mode=dev
 */
import { computed, getCurrentInstance, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'

import { events$ } from '@/services'

import isEqual from 'lodash/isEqual'

import useBrowser from '@/hooks/useBrowser'

import { EVENT_MODAL } from '@/config/events'

// HOOKS
const { currentViewport } = useBrowser()

// INIT
const MAX_MOBILE_MENU_HEIGHT = 250
const MAX_DESKTOP_MENU_HEIGHT = 500
const MIN_MENU_BOTTOM_MARGIN = 16 // to have a small margin between menu and edge of browser
const emit = defineEmits(['close', 'select'])
const props = defineProps({
  manualFocus: {
    type: Boolean,
    default: false,
  },

  name: {
    type: String,
    required: true,
  },

  options: {
    type: Array,
    required: true,
  },

  selected: {
    type: [String, Object, Number],
    default: '',
  },

  size: {
    validator(value) {
      return ['sm', 'md'].includes(value)
    },
    default: 'sm',
  },

  trigger: {
    type: Object,
    default: () => {},
  },
})

defineOptions({
  inheritAttrs: false,
})

// DATA
const dropdownRef = ref(null)
const isOpen = ref(false)
const isDropdownPresent = ref(true)
const triggerEl = ref(null)
const position = ref({
  display: 'none',
})

// COMPUTED
const classes = computed(() => {
  return {
    [`dropdown--${props.size}`]: true,
    'dropdown--active': isOpen.value,
  }
})

const maxMenuHeight = computed(() => {
  return currentViewport.value === 'small' ? MAX_MOBILE_MENU_HEIGHT : MAX_DESKTOP_MENU_HEIGHT
})

/*
  If the dropdown lies within a modal we need to teleport it inside of the modal
  as the a11y-dialog adds a focus-trap which prevents a dropdown defined
  outside of the modal from getting focus
*/
const parentModal = computed(() => {
  const instance = getCurrentInstance()

  const checkInstance = inst => {
    if (!inst) {
      return false
    }

    // @note: We check, if it's the modal, when the instance name equals 'Modal'
    if (inst.type?.__name === 'Modal') {
      return inst.refs.modalContainerRef
    } else {
      return checkInstance(inst.parent)
    }
  }

  return checkInstance(instance)
})

// METHODS
function calculateMenuPosition(triggerHeight, menuHeight, verticalMargins) {
  let bottom, height, top

  const setMenuUnderTrigger = () => {
    top = verticalMargins.top + triggerHeight
    bottom = null
  }

  const setMenuAboveTrigger = () => {
    bottom = verticalMargins.bottom + triggerHeight
    top = null
  }

  // Enough space under trigger -> show menu at bottom of trigger
  if (verticalMargins.bottom > menuHeight + MIN_MENU_BOTTOM_MARGIN) {
    setMenuUnderTrigger()
    return { bottom, height, top }
  }

  // Enough space above trigger -> show menu at top of trigger
  if (verticalMargins.top > menuHeight + MIN_MENU_BOTTOM_MARGIN) {
    setMenuAboveTrigger()
    return { bottom, height, top }
  }

  // Not enough vertical space, pick the bigger space and reduce max height of menu
  if (verticalMargins.top > verticalMargins.bottom) {
    setMenuAboveTrigger()
    height = verticalMargins.top - MIN_MENU_BOTTOM_MARGIN
  } else {
    setMenuUnderTrigger()
    height = verticalMargins.bottom - MIN_MENU_BOTTOM_MARGIN
  }

  return { bottom, height, top }
}

function close() {
  isOpen.value = false
  emit('close')
}

function defineTrigger() {
  if (dropdownRef.value.children.length === 0) return
  triggerEl.value = props.trigger || document.activeElement
}

function findOption() {
  if (!props.selected) return props.options[0]

  const isSelectedString = typeof selected == 'string'
  if (!isSelectedString && Object.keys(props.selected).length === 0) return props.options[0]

  // if there's already a value selected, but it's not in the list, return the first option
  return (
    props.options.find(option => {
      if (isSelectedString) {
        return option.value === props.selected
      } else {
        return isEqual(option.value, props.selected)
      }
    }) || props.options[0]
  )
}

function handleClick(event) {
  if (!event.target.closest('.dropdown')) {
    close()
  }
}

/*
  We need to manually unmount the dropdown before the modal (parent) is destroyed
  otherwise the teleport target is invalid and throws an error
*/
function hideBeforeModalClosing(modalId) {
  const parentModalId = parentModal.value.id
  if (parentModalId === modalId) {
    isDropdownPresent.value = false
  }
}

async function initMenu() {
  // Menu needs to be visible before we can calculate the position
  showMenu()
  await nextTick()
  defineTrigger()
  setPosition()
  if (!props.manualFocus) setFocus()
}

function getVerticalMargins(rect) {
  const distanceToBottom = window.innerHeight - (rect.top + rect.height)
  const distanceToTop = rect.top
  return { top: distanceToTop, bottom: distanceToBottom }
}

function open() {
  isOpen.value = true
}

function selectItem(item) {
  emit('select', item)
  close()
}

function setCssPositionVariable(menuPosition, rect) {
  if (menuPosition.top === null) {
    position.value.top = 'auto'
  } else {
    // If menu within modal, exclude scroll position from calculating top
    position.value.top = `${menuPosition.top + (parentModal.value ? 0 : window.scrollY)}px`
  }

  if (menuPosition.bottom === null) {
    position.value.bottom = 'auto'
  } else {
    // If menu within modal, exclude scroll position from calculating top
    position.value.bottom = `${menuPosition.bottom - (parentModal.value ? 0 : window.scrollY)}px`
  }
  position.value.left = `${rect.left}px`
  position.value.width = `${rect.width}px`
  position.value.maxHeight = `${menuPosition.height || maxMenuHeight.value}px`
}

function setFocus() {
  const idToFocus = findOption()
  const idRef = Array.from(dropdownRef.value.children).find(item => item.id === `${props.name}-${idToFocus.value}`)
  const focusItem = idRef.getElementsByTagName('input')[0]
  focusItem.focus({ preventScroll: true })
}

function setPosition() {
  const rect = triggerEl.value.getBoundingClientRect()
  const menuHeight = Math.min(dropdownRef.value.offsetHeight, maxMenuHeight.value)
  const verticalMargins = getVerticalMargins(rect)
  const menuPosition = calculateMenuPosition(rect.height, menuHeight, verticalMargins)
  setCssPositionVariable(menuPosition, rect)
}

function showMenu() {
  position.value.display = 'block'
}

function toggle() {
  isOpen.value = !isOpen.value
}

// WATCHER
watch(
  () => isOpen.value,
  async val => {
    if (val) {
      window.addEventListener('resize', setPosition)
      await nextTick()

      // @note: attaching the event needs to be delayed, otherwise the menu won't open
      window.setTimeout(() => {
        document.addEventListener('click', handleClick)
      }, 100)

      initMenu()
    } else {
      position.value.display = 'none'
      if (!props.manualFocus) triggerEl.value.focus()
      document.removeEventListener('click', handleClick)
      window.removeEventListener('resize', setPosition)
    }
  }
)

onMounted(() => {
  if (parentModal.value) {
    events$.on(EVENT_MODAL.BEFORE_CLOSING, hideBeforeModalClosing)
  }
})

onBeforeUnmount(() => {
  if (parentModal.value) {
    events$.off(EVENT_MODAL.BEFORE_CLOSING, hideBeforeModalClosing)
  }
})

defineExpose({
  close,
  isOpen,
  open,
  setFocus,
  toggle,
})
</script>

<style scoped>
.dropdown--sm {
  --select-font-size: var(--font-size-regular-md);
  --select-letter-spacing: var(--letter-spacing-regular-md);
  --select-line-height: var(--line-height-regular-md);
}

.dropdown--md {
  --select-font-size: var(--font-size-regular-xl);
  --select-letter-spacing: var(--letter-spacing-regular-xl);
  --select-line-height: var(--line-height-regular-xl);
}

.dropdown {
  display: v-bind('position.display');
  position: absolute;
  left: v-bind('position.left');
  top: v-bind('position.top');
  bottom: v-bind('position.bottom');
  width: v-bind('position.width');
  list-style: none;
  background-color: var(--on-primary);
  border-radius: var(--fixed-border-radius-fix-02);
  box-shadow: var(--elevation-level-3);
  overflow-y: auto;
  max-height: 0;

  opacity: 0;
  z-index: var(--dvp-stack-level-popup-menu);

  --select-color-item: var(--on-surface);
  --select-background-color-item: var(--Interaction-States-Dropdowns-default);
  --select-background-color-item-hovered: var(--Interaction-States-Dropdowns-hovered);
  --select-background-color-item-focused: var(--Interaction-States-Dropdowns-focused);
  --select-background-color-item-active: var(--Interaction-States-Dropdowns-pressed);
  --select-background-color-item-selected: var(--Interaction-States-Dropdowns-Selected);
  --select-background-color-item-disabled: var(--Interaction-States-Dropdowns-disabled);

  &.dropdown--active {
    opacity: 1;
    max-height: v-bind('position.maxHeight');
  }
}

.dropdown__item {
  position: relative;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;
  color: var(--select-color-item);
  font-size: var(--select-font-size);
  letter-spacing: var(--select-letter-spacing);
  line-height: var(--select-line-height);

  &:first-child .item__label {
    border-top-left-radius: var(--fixed-border-radius-fix-02);
    border-top-right-radius: var(--fixed-border-radius-fix-02);
  }

  &:last-child .item__label {
    border-bottom-left-radius: var(--fixed-border-radius-fix-02);
    border-bottom-right-radius: var(--fixed-border-radius-fix-02);
  }

  input[type='radio'] {
    position: absolute;
    left: 0;
    opacity: 0;
  }

  &.disabled {
    pointer-events: none;

    .item__label {
      background-color: var(--select-background-color-item-disabled);
      text-decoration: line-through;
    }
  }

  &:hover {
    background-color: var(--select-background-color-item-hovered);
  }

  &[aria-selected='true'] {
    background-color: var(--select-background-color-item-selected);

    .item__label svg {
      width: 16px;
      height: 16px;
      color: var(--on-surface-medium);
    }
  }

  &:has(input:focus) {
    background-color: var(--select-background-color-item-focused);
  }
}

.item__label {
  padding: var(--fixed-spacing-fix-02) var(--fixed-spacing-fix-06) var(--fixed-spacing-fix-02)
    var(--fixed-spacing-fix-04);
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
  color: var(--select-color-item);
  word-break: break-all;
}
</style>
